import React, { useContext } from 'react';
import { GlobalDataContext } from '../../context/context';
import {Link} from 'react-router-dom';

const BlockAboutTwo = (props) => {
    const { rpdata } = useContext(GlobalDataContext);

        let publicUrl = process.env.PUBLIC_URL+'/'

    return <div className="about-area pd-top-120 pd-bottom-120">
			  <div className="container">
			    <div className="about-area-inner">
			      <div className="row">
			        <div className="col-lg-6 col-md-10">
			          <div className="about-thumb-wrap style-two" style={{ backgroundImage:`url("${rpdata?.gallery?.[38]}")`, backgroundSize:"cover"}}>
			          </div>
			        </div>
			        <div className="col-lg-6">
			          <div className="about-inner-wrap">  
			            <div className="section-title mb-0">
			              <h6 className="sub-title style-btn">ABOUT US</h6>
			              <h2 className="title">{rpdata?.dbSlogan?.[4].slogan}</h2>
			              <p className="content">{rpdata?.dbAbout?.[1].text}</p>
			            </div>
			          </div>
                      <Link className="btn btn-border-black " to="/contact">
                    Free Estimate
                  </Link>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
        }

export default BlockAboutTwo